import _ from "lodash";

import { CountryCodeSupported } from "./country";

import { isRole, Role } from "src/common/config";

export const isSandboxEnvironment = isRole(Role.Sandbox);

export const DoubleReferralRewardEndDate = "21 March 2025";

export const isCountryCodeSupported = (countryCode: string) =>
  _.toUpper(countryCode) in CountryCodeSupported;

export const DOUBLE_REWARDS_REFERRAL_SCHEME_TERMS =
  "https://gocardless.com/faq/referral-double-rewards/";

// Image source:
// First run => https://app.contentful.com/spaces/40w0m41bmydz/assets/66px9gYVVnabONs7ySHd19?focusedField=file&focusedLocale=de
// Second run => https://app.contentful.com/spaces/40w0m41bmydz/assets/6ogp5pbQnl5OMQXw2YO37A?focusedField=title&focusedLocale=en
export const referPageImageURLs: Record<string, string> = {
  [CountryCodeSupported.US]:
    "https://gocardless.com/assets/40w0m41bmydz/6ogp5pbQnl5OMQXw2YO37A/1502a17a1b35f834c9b491a2ada994b6/ReferPage.png",
  [CountryCodeSupported.CA]:
    "https://gocardless.com/assets/40w0m41bmydz/6ogp5pbQnl5OMQXw2YO37A/6bef5c15dd0c7d59d9ff3230e7a8cff9/ReferPage.png",
  [CountryCodeSupported.DK]:
    "https://gocardless.com/assets/40w0m41bmydz/66px9gYVVnabONs7ySHd19/fd3fbb5befc64a8a148408d79019632d/DK-refer-page.png", // DK is excluded from double rewards referral second run
  [CountryCodeSupported.AU]:
    "https://gocardless.com/assets/40w0m41bmydz/6ogp5pbQnl5OMQXw2YO37A/e90493c764495c23b3a6e7139f12a07c/ReferPage.png",
  [CountryCodeSupported.SE]:
    "https://gocardless.com/assets/40w0m41bmydz/66px9gYVVnabONs7ySHd19/440406c9184bddd097e98f68920b90a5/Refer-page__1_.jpg", // SE is excluded from double rewards referral second run
  [CountryCodeSupported.ES]:
    "https://gocardless.com/assets/40w0m41bmydz/6ogp5pbQnl5OMQXw2YO37A/da924e8429f1990384501af388501930/ReferPage.png",
  [CountryCodeSupported.FR]:
    "https://gocardless.com/assets/40w0m41bmydz/6ogp5pbQnl5OMQXw2YO37A/f02df2f21ff26b428e0e186865a26213/ReferPage.png",
  [CountryCodeSupported.GB]:
    "https://gocardless.com/assets/40w0m41bmydz/6ogp5pbQnl5OMQXw2YO37A/03b422d4737bfcd8006629de1c22a864/ReferDashboardPage.png",
  [CountryCodeSupported.NZ]:
    "https://gocardless.com/assets/40w0m41bmydz/6ogp5pbQnl5OMQXw2YO37A/745732a60f91a9e0e14e235ccb066f08/ReferPage.png",
};

// Image sorce:
// First run => https://app.contentful.com/spaces/40w0m41bmydz/assets/14mduUfXAutlen5C3Te8kM?focusedField=file&focusedLocale=sv
//              and https://app.contentful.com/spaces/40w0m41bmydz/assets/5SxSoqVnhLSzmQBW3wM3XX?focusedField=file&focusedLocale=da-DK
// Second run => https://app.contentful.com/spaces/40w0m41bmydz/assets/6Apj6QU31FmNNVIFRUowk1?focusedField=file&focusedLocale=en
export const marketingImageURLs: Record<string, string> = {
  [CountryCodeSupported.AU]:
    "https://gocardless.com/assets/40w0m41bmydz/6Apj6QU31FmNNVIFRUowk1/a7443e3697341e2cb34ff45179926553/SignupPage.png",
  [CountryCodeSupported.CA]:
    "https://gocardless.com/assets/40w0m41bmydz/6Apj6QU31FmNNVIFRUowk1/43d65377695f6eb8a62d2960def0fdf0/SignupPage.png",
  [CountryCodeSupported.DK]:
    "https://gocardless.com/assets/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/bbc8f917df6668f7ee6ca72e0cb979b2/DK-Signin-image.png", // DK is excluded from double rewards referral second run
  [CountryCodeSupported.ES]:
    "https://gocardless.com/assets/40w0m41bmydz/6Apj6QU31FmNNVIFRUowk1/fc33bec94f79dfefd6256e23a7d5431d/SignupPage.png",
  [CountryCodeSupported.FR]:
    "https://gocardless.com/assets/40w0m41bmydz/6Apj6QU31FmNNVIFRUowk1/bd62e527b23da971b37daf11e90e6549/SignupPage.png",
  [CountryCodeSupported.GB]:
    "https://gocardless.com/assets/40w0m41bmydz/6Apj6QU31FmNNVIFRUowk1/4585cc9eb065b12d863432f05f4a02f4/SignupPage.png",
  [CountryCodeSupported.NZ]:
    "https://gocardless.com/assets/40w0m41bmydz/6Apj6QU31FmNNVIFRUowk1/a675d8264da9d859f079f74d5a59966b/SignupPage.png",
  [CountryCodeSupported.SE]:
    "https://gocardless.com/assets/40w0m41bmydz/14mduUfXAutlen5C3Te8kM/b2949b91775ce17b3a411157f52fa083/Login-Signup__1_.png", // SE is excluded from double rewards referral second run
  [CountryCodeSupported.US]:
    "https://gocardless.com/assets/40w0m41bmydz/6Apj6QU31FmNNVIFRUowk1/8178f3dba3211b1f502b6644ec4ee16e/SignupPage.png",
};

export const defaultDoubleRewardsReferPageImageURL =
  "https://gocardless.com/assets/40w0m41bmydz/6ogp5pbQnl5OMQXw2YO37A/a8a6f3f51c80705a68679d7a2ff0336c/ReferPage.png";
export const defaultDoubleRewardsMarketingImageURL =
  "https://gocardless.com/assets/40w0m41bmydz/6Apj6QU31FmNNVIFRUowk1/ce523064bba459eb6c3026defc4f601d/SignupPage.png";
